:root {
  --padding-tab-end: 3em;
}
.timeline-item {
  display: flex;
  position: relative;
  padding: 0em 0 1.4em;
}

.timeline-container {
  position: relative;
  padding-bottom: 15px;
  padding-left: var(--padding-tab-end);
}

.timeline-item::before {
  background-color: #e7edf5f5;
  border:"1px dashed red";
  content: "";
  position: absolute;
  left: calc(10px - var(--padding-tab-end));
  top: 0;
  width: 1px;
  height: 100%;
}

.timeline-item-content {
  border-radius: 5px;
  /* display: flex; */
  position: relative;
}
.timeline-item:first-child {
  padding-top: 0;
}
.timeline-item:last-child {
  padding: 0;
}

.timeline-item-content .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5eff7;
  border-radius: 5px;
  position: absolute;
  left: calc(-2px - var(--padding-tab-end));
  top: 0;
  width: 24px;
  height: 24px;
  z-index: 2;
}
